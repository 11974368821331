/* body {
  margin: 0;
  font-family: "Helvetica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600;700&display=swap");
body {
  font-family: "IBM Plex Sans Thai", "Helvetica";
}
span {
  font-family: "IBM Plex Sans Thai", "Helvetica";
}
a {
  font-family: "IBM Plex Sans Thai", "Helvetica";
}

label {
  font-family: Sarabun;
}
code {
  font-family: "Helvetica";
}
svg {
  vertical-align: baseline;
}
.ant-menu-item-selected {
  background-color: #1b4586 !important;
  color: #fff !important;
  margin: 0 auto !important;
  border-radius: 8px;
  display: flex !important;
  align-items: center !important;
}

.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #1b4586 !important;
  :hover {
    color: white !important;
  }
}
.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border-right: 0px !important;
}
/* .ant-table-thead .ant-table-cell {
  background-color: #f3f6f9;
} */
